<template>
  <title-link-wrapper :to="{ name: 'Traders' }" :title="$t('components.singleTrader.title')">
    <collapse :title="$t('components.singleTrader.colapseTitle')">
      <v-row>
        <v-col
          ><b>{{ $t('helpers.fullName') }}: </b>{{ trader.firstName }} {{ trader.lastName }}</v-col
        >
        <v-spacer></v-spacer>
        <v-col class="text-right"
          >{{ $t('helpers.sum') }} ({{ $t('helpers.net') }}):
          <b>{{
            formatCurrency(
              traderFilteredProfit,
              {
                name: 'PLN',
              },
              false
            )
          }}</b></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><b>{{ $t('helpers.phoneNumber') }}: </b>{{ trader.phone }}</v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><b>{{ $t('helpers.email') }}: </b>{{ trader.email }}</v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><b>{{ $t('helpers.code') }}: </b>{{ trader.salesmanCode }}</v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><b>{{ $t('helpers.discount') }}: </b
          >{{ roundedDiscountPercentage(trader.discountPercentage) }}%</v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><primary-button :onClick="openModal">{{ $t('helpers.edit') }}</primary-button></v-col
        >
        <change-address-modal
          :open="modal"
          :title="$t('components.singleTrader.modal.title')"
          :agree="$t('components.singleTrader.modal.agree')"
          :disagree="$t('components.singleTrader.modal.disagree')"
          v-on:decline="decline"
          v-on:accept="accept"
          :loading="loadingEditButton"
        >
          <v-form v-model="isFormValid" ref="form" class="mt-4">
            <v-row>
              <v-col class="py-0 py-sm-2" col="4">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.firstName') }}
                </h2>
                <v-text-field
                  v-model="editTrader.firstName"
                  :rules="validator.requiredRules"
                  hide-details="auto"
                  outlined
                  solo
                  dense
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="8">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.lastName') }}
                </h2>
                <v-text-field
                  v-model="editTrader.lastName"
                  :rules="validator.requiredRules"
                  outlined
                  hide-details="auto"
                  solo
                  dense
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.email') }}
                </h2>
                <v-text-field
                  v-model="editTrader.email"
                  :rules="validator.emailRules"
                  outlined
                  hide-details="auto"
                  solo
                  dense
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.phoneNumber') }}
                </h2>
                <v-text-field
                  v-model="editTrader.phone"
                  :rules="validator.phoneRules"
                  outlined
                  hide-details="auto"
                  solo
                  dense
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.code') }}
                </h2>
                <v-text-field
                  v-model="editTrader.salesmanCode"
                  :rules="validator.requiredRules"
                  outlined
                  hide-details="auto"
                  solo
                  dense
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.discount') }}
                </h2>
                <v-text-field
                  v-model="editTrader.discountPercentage"
                  :rules="validator.discountRules"
                  outlined
                  hide-details="auto"
                  suffix="%"
                  solo
                  dense
                  elevation="0"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </change-address-modal>
      </v-row>
    </collapse>
    <my-data-table
      :data="companies"
      :headers="headers"
      :loading="loading"
      :length="length"
      v-bind:page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="12" sm="5">
          <v-menu
            ref="menu"
            v-model="fromDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.fromDate"
                :label="$t('components.singleTrader.table.timeFrom')"
                readonly
                solo
                class="stretch-height"
                hide-details
                elevation="0"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.fromDate"
              no-title
              scrollable
              :locale="selectedLanguage"
              :max="params.toDate"
              @input="fromDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="5">
          <v-menu
            ref="menu"
            v-model="toDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.toDate"
                :label="$t('components.singleTrader.table.timeTo')"
                readonly
                solo
                class="stretch-height"
                hide-details
                elevation="0"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.toDate"
              :min="params.fromDate"
              no-title
              :locale="selectedLanguage"
              scrollable
              @input="toDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>

      <template v-slot:actions="slotProps">
        <router-link :to="{ name: 'SingleTrader', params: { traderId: slotProps.item._id } }">
          <v-list-item class="hover-item-popup">
            <v-list-item-content
              >{{ $t('components.singleTrader.table.showDetails') }}
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </template>
    </my-data-table>
  </title-link-wrapper>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import Collapse from '../../../components/Collapse.vue';
import MyDataTable from '../../../components/MyDataTable.vue';
import TitleLinkWrapper from '../../../components/TitleLinkWrapper.vue';
import SalesmanService from '../../../services/SalesmanService';
import CompanyService from '../../../services/CompanyService';
import ChangeAddressModal from '../../../components/modals/ChangeAddressModal.vue';
import { validators } from '../../../validators/Validators';
import { round, sum } from 'lodash';
import { formatCurrency } from '../../../mixins/formatCurrency';
import { sortHandler } from '../../../mixins/sortHandler';
import { selectedLanguageComputed } from '../../../store/helper.js';
import { formMixin } from '../../../mixins/formMixin';

export default {
  name: 'SingleTrader',
  components: { TitleLinkWrapper, Collapse, PrimaryButton, MyDataTable, ChangeAddressModal },
  mixins: [formatCurrency, sortHandler, formMixin],
  data() {
    return {
      count: 0,
      itemsPerPage: 6,
      dates: [],
      isFormValid: false,
      trader: {},
      length: 0,
      fromDateMenu: false,
      toDateMenu: false,
      loadingEditButton: false,
      modal: false,
      loading: true,
      params: { fromDate: '', toDate: '', page: 1, limit: 6, sort: '' },
      companies: [],
      editTrader: {},
    };
  },
  computed: {
    ...selectedLanguageComputed,
    headers() {
      return [
        {
          text: this.$i18n.t('helpers.companyName'),
          align: 'start',
          value: 'companyName',
        },
        { text: this.$i18n.t('helpers.registerForm'), value: 'nip' },
        {
          text: this.$i18n.t('components.singleTrader.headers.sumOfOrders'),
          value: 'convertedFullProfit',
        },
      ];
    },

    validator() {
      return validators;
    },
    traderFilteredProfit() {
      const traderProfits = this.companies.map((company) => company.convertedFullProfit);
      return round(sum(traderProfits), 2);
    },
  },
  watch: {
    'params.page': function () {
      this.fetchData();
    },
  },
  methods: {
    mapTrader(trader) {
      this.trader = {
        ...trader,
        discountPercentage: trader.discountPercentage * 100,
      };
      this.editTrader = {
        ...trader,
        discountPercentage: trader.discountPercentage * 100,
      };
    },

    async accept() {
      if (!this.isFormValid) {
        this.$refs.form.validate();
        return;
      }
      try {
        this.loadingEditButton = true;
        const traderToEdit = {
          ...this.editTrader,
          discountPercentage: this.editTrader.discountPercentage / 100,
        };
        const response = await SalesmanService.update(this.$route.params.traderId, traderToEdit);
        this.mapTrader(response.data);
        this.modal = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEditButton = false;
      }
    },
    decline() {
      this.modal = false;
    },
    async clearParams() {
      this.params = { fromData: '', toDate: '', page: 1, limit: 6, sort: '' };
      await this.getFiltered();
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        this.loading = true;
        const responseCompanies = await CompanyService.getAllTraderCompanies(
          this.$route.params.traderId,
          this.params
        );

        this.companies = responseCompanies.data;
        this.count = responseCompanies.count;
        this.length = Math.ceil(this.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    openModal() {
      this.modal = true;
    },
    async fetchData() {
      try {
        const responseTrader = await SalesmanService.getOne(this.$route.params.traderId);
        this.trader = responseTrader.data;
        this.mapTrader(this.trader);

        await this.getFiltered();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    roundedProfit(profit) {
      return round(profit, 2);
    },
    roundedDiscountPercentage(discount) {
      return round(discount, 2);
    },
    fetchSort() {
      this.getFiltered();
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped></style>
